import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./diamond-social.scss"

const DiamondSocial = ({ iconType, href }) => {
  const data = useStaticQuery(graphql`
    {
      imgs: allFile(
        filter: {
          extension: { eq: "svg" }
          name: { regex: "/(behance-red|github-red|linkedin-red)/" }
        }
      ) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
    }
  `)

  const img = data.imgs.edges.filter(e => e.node.name === `${iconType}-red`)[0]
  
  return (
    <div className={`diamond-social ${iconType}`}>
      <img className="icon" src={img.node.publicURL} alt={img.node.name} />
      <a className="href-layer" href={href} target="_blank" rel="noopener noreferrer">{ img.node.name }</a>
    </div>
  )
}

DiamondSocial.propTypes = {
  iconType: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default DiamondSocial
