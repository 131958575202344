import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import BackgroundLines from "./../components/background-lines"

import "./skills.scss"

const Skills = () => {
  const data = useStaticQuery(graphql`
    {
      skills: allFile(
        filter: { extension: { eq: "svg" }, name: { eq: "skills" } }
      ) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
    }
  `)
  return (
    <div className="skills">
      <BackgroundLines
        firstColor="blue-dark"
        secondColor="white-light"
        clipped
      />
      <div className="uk-container content-container">
        <h2>skills</h2>
        <div className="img-wrapper">
          <img
            src={data.skills.edges[0].node.publicURL}
            alt={data.skills.edges[0].node.name}
          />
        </div>
      </div>
    </div>
  )
}

export default Skills
