/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import BackgroundLines from "./background-lines"
import Footer from "./footer"
import RwdContent from "./rwd-content"

import "./layout.css"
import "./../styles/body.scss"
import "uikit/dist/css/uikit.min.css"

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <RwdContent />
        <BackgroundLines />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
