import React from "react"

import Layout from "./../components/layout"
import SEO from "./../components/seo"
import MainSection from "./../sections/main"
import AboutSection from "./../sections/about"
import ExperienceSection from "./../sections/experience"
import SkillsSection from "./../sections/skills"
import ContactSection from "./../sections/contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Aleksander Maj" />
    <MainSection />
    <AboutSection />
    <ExperienceSection />
    <SkillsSection />
    <ContactSection />
  </Layout>
)

export default IndexPage
