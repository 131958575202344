import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

import BackgroundLines from "./../components/background-lines"

import "./about.scss"

const About = () => {
  const data = useStaticQuery(graphql`
    {
      activities: allFile(
        filter: { extension: { eq: "svg" }, name: { regex: "/activities-*/" } }
      ) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
      photo: file(relativePath: { eq: "photo.jpg" }) {
        childImageSharp {
          fixed(width: 300, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <>
      <div className="about" id="about">
        <BackgroundLines
          firstColor="gray-light"
          secondColor="white-light"
          clipped
        />
        <div className="uk-container content-container">
          <h2>about me</h2>
          <div className="uk-grid">
            <div className="uk-width-2-3">
              <p>
                I am a full-stack developer with passion to UX, therefore I
                prefer the front-end side because it allows me to create visual
                things that other people can see and try. I don't want to get
                attached to any particular technology. That's why currently I am
                working in Vue, but previously I did some projects in React and
                Angular.
              </p>

              <p>
                In my opinion, a front-end developer should have a basic
                knowledge of backend technologies. When I have to work on a task
                that needs small changes on the server-side, I don't have
                problems to work it on my own. I can contribute to Ruby on Rails
                and Node.js projects, but also I know Python basics so jumping
                into the project shouldn't take a lot of time.
              </p>

              <p>
                During my career, I have a small episode with designing and user
                experience. I was creating wireframes, mockups, and designs for
                small applications. Still, after hours I am doing designs and UX
                for side projects. Having at least basic knowledge in this area
                allows me to work on small designs on my own - without asking a
                designer for any help. It also improves how I can communicate
                and understand designers in their work and visions.
              </p>
            </div>
            <div className="uk-width-1-3">
              <div className="photo-container">
                <div className="photo-background">
                  <Img
                    className="photo"
                    fixed={data.photo.childImageSharp.fixed}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="activities">
        <BackgroundLines firstColor="gray" secondColor="white-light" clipped />
        <div className="uk-container content-container">
          <div className="uk-flex-between uk-flex">
            {data.activities.edges.map((e, idx) => (
              <img
                key={idx}
                className="activity-icon"
                src={e.node.publicURL}
                alt={e.node.name}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default About
