import React from "react"

import Logo from "./../components/logo"
import DiamondTile from "./../components/diamond-tile"
import DiamondSocial from "./../components/diamond-social"

import "./main.scss"

const Main = () => {
  return (
    <div className="uk-container main-section">
      <div className="logo-container">
        <div className="logo-wrapper">
          <Logo size={70} />
        </div>
        <div className="logo-background"></div>
      </div>

      <div className="gray-container">
        <div className="gray-background"></div>
      </div>

      <div className="blue-container">
        <div className="blue-background"></div>
      </div>

      <div className="red-container">
        <div className="red-background"></div>
      </div>

      <div className="photo-container">
        <div className="photo-background">
          <div className="photo"></div>
        </div>

        <DiamondTile
          extraClass="tile-1"
          iconType="about"
          href="#about"
          title="about"
        />
        <DiamondTile
          extraClass="tile-2"
          iconType="experience"
          href="#experience"
          title="experience"
        />
        <DiamondTile
          extraClass="tile-3"
          iconType="contact"
          href="#contact"
          title="contact"
        />
      </div>

      <div className="social-container">
        <DiamondSocial
          iconType="behance"
          href="http://www.behance.net/majaleksander"
        />
        <DiamondSocial
          iconType="linkedin"
          href="https://www.linkedin.com/profile/view?id=264250000"
        />
        <DiamondSocial iconType="github" href="https://github.com/iskandiar" />
      </div>

      <div className="hero-text">
        <h2>Hello!</h2>
        <p>
          I'm Aleksander Maj
          <br />
          Software developer
          <br />
          UX enthusiast
        </p>
      </div>
    </div>
  )
}

export default Main
