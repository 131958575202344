import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./diamond-tile.scss"

const DiamondTile = ({
  extraClass = "",
  iconType,
  href,
  title,
  target,
  size = 250,
}) => {
  const data = useStaticQuery(graphql`
    {
      imgs: allFile(
        filter: {
          extension: { eq: "svg" }
          name: {
            regex: "/(experience|contact|about|linkedin|behance|github|mail)/"
          }
        }
      ) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
    }
  `)

  const img = data.imgs.edges.filter(e => e.node.name === iconType)[0]

  return (
    <div className={`diamond-tile ${extraClass} diamond-tile-${size}`}>
      <div className="gray-background"></div>
      <div className="content">
        <img className="icon" src={img.node.publicURL} alt={img.node.name} />
        {title && <div>{title}</div>}
      </div>
      <a className="href-layer" href={href} target={target}>{img.node.name}</a>
    </div>
  )
}

DiamondTile.propTypes = {
  extraClass: PropTypes.string,
  iconType: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
  target: PropTypes.string,
  size: PropTypes.number,
}

export default DiamondTile
