import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./logo.scss"

const Logo = ({ size = 50, mainColor = "gray" }) => {
  const data = useStaticQuery(graphql`
    {
      logos: allFile(
        filter: {
          extension: { eq: "svg" }
          name: { regex: "/logo-(blue|red|gray|white)/" }
        }
      ) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
    }
  `)

  return (
    <div className="logo" style={{ width: `${size}px`, height: `${size}px` }}>
      {data.logos.edges
        .filter(e =>
          ["logo-blue", "logo-red", `logo-${mainColor}`].includes(e.node.name)
        )
        .map((e, idx) => (
          <img
            key={idx}
            className={e.node.name}
            src={e.node.publicURL}
            alt={e.node.name}
          />
        ))}
    </div>
  )
}

Logo.propTypes = {
  size: PropTypes.number,
  mainColor: PropTypes.string,
}

export default Logo
