import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

import BackgroundLines from "./../components/background-lines"

import "./experience.scss"

const Experience = () => {
  const data = useStaticQuery(graphql`
    {
      worldMap: file(relativePath: { eq: "world-map.png" }) {
        childImageSharp {
          fixed(width: 527) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <div className="experience" id="experience">
      <BackgroundLines
        firstColor="red-dark"
        secondColor="white-light"
        clipped
      />
      <div className="uk-container content-container">
        <h2>experience</h2>
        <div className="uk-grid">
          <div className="uk-width-1-3 timeline-container">
            <div className="timeline">
              <div className="line"></div>
              <div className="dots">
                <div className="dot top"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
              <div className="freelance">
                <span>Freelancer</span>
              </div>

              <div className="jobs">
                <div className="job">
                  <div className="name">
                    <span className="company">AdWeb</span>
                    <span className="position">Graphic design intern</span>
                  </div>
                  <div className="date">2012</div>
                </div>

                <div className="job">
                  <div className="name">
                    <span className="company">Silvermedia</span>
                    <span className="position">Jr. frontend developer</span>
                  </div>
                  <div className="date">2013</div>
                </div>

                <div className="job">
                  <div className="name">
                    <span className="company">Software Mansion</span>
                    <span className="position">Frontend developer</span>
                  </div>
                  <div className="date">2014</div>
                </div>

                <div className="job">
                  <div className="name">
                    <span className="company">Cherry Pick</span>
                    <span className="position">UX/UI Engineer</span>
                  </div>
                  <div className="date">2015</div>
                </div>

                <div className="job">
                  <div className="name">
                    <span className="company">Albacross</span>
                    <span className="position">Software developer</span>
                  </div>
                  <div className="date">2015-2017</div>
                </div>

                <div className="job">
                  <div className="name">
                    <span className="company">AirHelp</span>
                    <span className="position">Full-stack developer</span>
                  </div>
                  <div className="date">2017-2018</div>
                </div>

                <div className="job">
                  <div className="name">
                    <span className="company">Silverfin</span>
                    <span className="position">Javascript developer</span>
                  </div>
                  <div className="date">2018-?</div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-2-3">
            <div className="map">
              <Img
                className="world-map"
                fixed={data.worldMap.childImageSharp.fixed}
              />
              <div className="dot-container west-usa">
                <div className="dot small"></div>
              </div>

              <div className="dot-container east-usa">
                <div className="dot medium east-usa"></div>
              </div>

              <div className="dot-container canada">
                <div className="dot small canada"></div>
              </div>

              <div className="dot-container europe">
                <div className="dot big europe"></div>
              </div>

              <div className="dot-container egipt">
                <div className="dot medium egipt"></div>
              </div>

              <div className="dot-container australia">
                <div className="dot big australia"></div>
              </div>

              <div className="dot-container bali">
                <div className="dot medium bali"></div>
              </div>

              <div className="dot-container china">
                <div className="dot small china"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Experience
