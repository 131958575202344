import React from "react"

import DiamondTile from "./diamond-tile"
import Logo from "./logo"

import "./rwd-content.scss"

const RwdContent = () => (
  <div className="rwd-layout">
    <div className="uk-container">
      <Logo mainColor="white" size={80} />
      Sorry, I am too busy to finish responsive version
      <div className="email-wrapper">
        <div className="email-text">contact@maj.design</div>
      </div>
      <div className="diamonds-wrapper">
        <DiamondTile
          extraClass="tile-1"
          iconType="linkedin"
          href="https://www.linkedin.com/in/aleksander-maj-5545b674/"
          target="_blank"
          size={120}
        />
        <DiamondTile
          extraClass="tile-2"
          iconType="behance"
          href="http://www.behance.net/majaleksander"
          target="_blank"
          size={120}
        />
        <DiamondTile
          extraClass="tile-3"
          iconType="github"
          href="https://github.com/iskandiar"
          target="_blank"
          size={120}
        />
      </div>
    </div>
  </div>
)

export default RwdContent
