import React from "react"

import DiamondTile from "./../components/diamond-tile"

import "./contact.scss"

const Contact = () => (
  <div>
    <div className="contact-diamonds" id="contact">
      <div className="uk-container">
        <div className="uk-column-1-2">
          <div className="email-wrapper">
            <div className="email-text">contact@maj.design</div>
          </div>
          <div className="diamonds-wrapper">
            <DiamondTile
              extraClass="tile-1"
              iconType="linkedin"
              href="https://www.linkedin.com/in/aleksander-maj-5545b674/"
              target="_blank"
              size={120}
            />
            <DiamondTile
              extraClass="tile-2"
              iconType="behance"
              href="http://www.behance.net/majaleksander"
              target="_blank"
              size={120}
            />
            <DiamondTile
              extraClass="tile-3"
              iconType="github"
              href="https://github.com/iskandiar"
              target="_blank"
              size={120}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="contact-white">
      <div className="uk-container uk-container-small">
        <p className="uk-margin-small-bottom uk-text-lighter uk-text-justify">
          If I had done classes in JavaScript back in May 1995, I would have
          been told that it was too much like Java or that JavaScript was
          competing with Java … I was under marketing orders to make it look
          like Java but not make it too big for its britches … [it] needed to be
          a silly little brother language.
        </p>
        <em className="uk-text-bold">—Brendan Eich</em>
      </div>
    </div>
  </div>
)

export default Contact
