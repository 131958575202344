import React from "react"

import "./background-lines.scss"

const BackgroundLines = ({
  firstColor = "red",
  secondColor = "blue",
  clipped = false,
}) => {
  const firstColorClass = `${firstColor}-line first-line color-line`
  const secondColorClass = `${secondColor}-line color-line`

  const mainClass = clipped ? "lines clipped" : "lines"
  return (
    <div className={mainClass}>
      <div className="lines-container top">
        <div className={firstColorClass}></div>
        <div className={secondColorClass}></div>
        <div className={firstColorClass}></div>
        <div className={secondColorClass}></div>
        <div className={firstColorClass}></div>
        <div className={secondColorClass}></div>
        <div className={firstColorClass}></div>
        <div className={secondColorClass}></div>
        <div className={firstColorClass}></div>
        <div className={secondColorClass}></div>
        <div className={firstColorClass}></div>
        <div className={secondColorClass}></div>
      </div>

      <div className="lines-container bottom">
        <div className={firstColorClass}></div>
        <div className={secondColorClass}></div>
        <div className={firstColorClass}></div>
        <div className={secondColorClass}></div>
        <div className={firstColorClass}></div>
        <div className={secondColorClass}></div>
        <div className={firstColorClass}></div>
        <div className={secondColorClass}></div>
        <div className={firstColorClass}></div>
        <div className={secondColorClass}></div>
        <div className={firstColorClass}></div>
        <div className={secondColorClass}></div>
      </div>
    </div>
  )
}

export default BackgroundLines
