import React from "react"

import Logo from "./logo"

import "./footer.scss"

const Footer = () => (
  <footer>
    <div className="uk-container">
      <div className="uk-column-1-3">
        <div>&nbsp;</div>
        <div>
          <div className="logo-wrapper">
            <Logo mainColor="white" size={40} />
          </div>
        </div>
        <div className="footer-text">
          All rights reserved © {new Date().getFullYear()} maj.design
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
